<template>
  <CModal id="TransReport" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template>
      <CAlert color="info">
        <CIcon name="cil-bell" /> 此功能將會篩選出區間內<b>{{ $t('Logistics.LogisticsSystem.' + SelectSystem.toUpperCase()) }}</b>配送系統且狀態為<b>準備中</b>的訂單，用於與配送供應商的司機確認當日出貨資料，成功列印後同時也會將當日總表送至物流供應商進行確認。
      </CAlert>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsSystem.' + SelectSystem.toUpperCase()) }}
        </CCol>
      </CRow>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsTracking.ShippingDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="ShippingDate" mode="date" :max-date="$dayjs().format('YYYY-MM-DD')">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput :value="inputValue" v-on="inputEvents" :placeholder="$t('Logistics.TransReportDateInfo')">
                <template #prepend-content>
                  <CIcon name="cil-calendar"/>
                </template>
                <template #append>
                  <CButton type="button" color="danger" @click="ShippingDate = ''">
                    <CIcon size="sm" name="cil-x"/>
                  </CButton>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.TransReport') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="PrintTransReport()" color="success">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateLogisticsOrder",
  props: ['Toggle', 'SelectSystem'],
  data() {
    return {
      Submit: false,
      Loading: true,
      LogisticsData: {
        ID: '',
        Temperature: 'Common'
      },
      ShippingDate: '',
      OrderList: [],
      LogisticsOption: [],
      LogisticsListObject: {}
    }
  },
  computed: {
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.TemperatureOptions')[status]
        }
      })
    }
  },
  mounted() {
   return Promise.all([
   ]).then(() => {
     this.Loading = false
   }).catch((error) => {
     console.log(error)
     this.Loading = false
     this.$notify({
       group: 'notify',
       title: this.$t('Message.Error'),
       text: this.$t('Message.Global/ErrorMessage') + error,
       type: 'error'
     })
   })
  },
  watch: {
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:SelectSystem', '')
    },
    PrintTransReport() {
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/order/transReport',
        method: 'post',
        data: {
          ShippingDate: this.ShippingDate ? this.$dayjs(this.ShippingDate).format('YYYY-MM-DD') : this.$dayjs().format('YYYY-MM-DD'),
          Logistics: {
            System: this.SelectSystem
          }
        }
      }).then(({data}) => {
        this.Submit = false
        this.$Progress.finish()
        if (this.SelectSystem === 'hct-logistics') {
          const BinaryString = window.atob(data)
          const Bytes = new Uint8Array(BinaryString.length)
          for (let index = 0; index < BinaryString.length; index++) {
            Bytes[index] = BinaryString.charCodeAt(index)
          }
          const Data = new Blob([Bytes], {type: 'application/pdf'})
          this.HandleHideModal()
          return window.open(URL.createObjectURL(Data), '_blank')
        }
        return window.open(data, '_blank')
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
